import React from "react";

const Center = () => {
  let CenterDetails = [
    {
      id: 1,
      img: "https://ik.imagekit.io/bhavishya/web-final/Center/NoidaSector71-Center.jpeg?updatedAt=1689407241196",
      address: "Sector 71, Noida",
    },
    {
      id: 2,
      img: "https://ik.imagekit.io/bhavishya/web-final/Center/Noida14A.jpeg?updatedAt=1689880060001",
      address: "Sector 14A, Noida",
    },
    {
      id: 3,
      img: "https://ik.imagekit.io/bhavishya/web-final/Center/Greater-Noida.jpeg?updatedAt=1689880060013",
      address: "Greater Noida",
    },
    {
      id: 4,
      img: "https://ik.imagekit.io/bhavishya/web-final/Center/Section119-Noida.jpeg?updatedAt=1689880060017",
      address: "Sector 119, Noida",
    },
    {
      id: 5,
      img: "https://ik.imagekit.io/bhavishya/web-final/Center/Vocational-Training-Center.jpeg?updatedAt=1689880059913",
      address: "Vocational Training Centre1",
    },
    {
      id: 6,
      img: "https://ik.imagekit.io/bhavishya/web-final/Center/Sector45-Noida.jpeg?updatedAt=1689880310111",
      address: "Sector 45, Noida",
    },
    {
      id: 7,
      img: "https://ik.imagekit.io/bhavishya/web-final/Center/Sector44-Noida.jpeg?updatedAt=1689880310191",
      address: "Sector 44, Noida",
    },
    {
      id: 8,
      img: "https://ik.imagekit.io/bhavishya/web-final/Center/Muradabad.jpeg?updatedAt=1689880310075",
      address: "Muradabad",
    },
    {
      id: 9,
      img: "https://ik.imagekit.io/bhavishya/web-final/Center/Madhubani.jpeg?updatedAt=1689880310080",
      address: "Madhubani",
    },
    {
      id: 10,
      img: "https://ik.imagekit.io/bhavishya/web-final/Center/FaridabadCenter.jpeg?updatedAt=1688227710638",
      address: "Faridabad, Haryana",
    },
    {
      id: 11,
      img: "https://ik.imagekit.io/bhavishya/web-final/Center/SiliguriCenter.jpeg?updatedAt=1688227710733",
      address: "Siliguri , West Bengal",
    },
    {
      id: 12,
      img: "https://ik.imagekit.io/bhavishya/web-final/Center/BuxarCenter.jpeg?updatedAt=1688228710043",
      address: "Buxar, Bihar",
    },
    {
      id: 13,
      img: "https://ik.imagekit.io/bhavishya/web-final/Center/RohtasCenter.jpeg?updatedAt=1688228934949",
      address: "Rohtas, Bihar",
    },
  ];

  return (
    <div>
      <div class="bg-primaryBg mb-10">
        <div class=" font-bold py-8 text-4xl md:text-6xl	text-white text-center bg-borderRed font-Cheri">
          Our Centres
        </div>

        <div className="mx-10 md:mx-16 space-y-10 lg:space-y-0 grid grid-cols-1 lg:grid-cols-2 lg:gap-10 ">
          {CenterDetails.map((item, i) => (
            <div className="text-center shadow-xl md:space-y-4 py-2">
              <div class="overflow-hidden rounded-lg ">
                <img
                  src={item.img}
                  class="hover:scale-125 duration-1000 mx-auto w-2/3 h-96"
                  alt={item.title}
                />
              </div>
              <div>
                <p className="py-2 text-2xl md:text-4xl font-bold font-Poopins px-4">
                  {item.address}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Center;
