import React from "react";
import { DigitalMedia, PrintMedia } from "../components";

const MediaPage = () => {
  return (
    <div>
      <DigitalMedia />
      <PrintMedia />
    </div>
  );
};

export default MediaPage;
