import React from "react";
import { FiLinkedin } from "react-icons/fi";
import {
  RiFacebookCircleLine,
  RiInstagramLine,
  RiTwitterLine,
  RiWhatsappLine,
} from "react-icons/ri";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { Logo } from "../assets";

const Footer = () => {
  let footerLinks = {
    Home: "/",
    "About Us": "/#about-us",
    Terms: "/",
    Volunteer: "/contact",
    "Donate Us": "/donate",
    "Contact Us": "/contact",
    Sitemap: "/",
    "Terms of Use": "/",
  };

  return (
    <footer className="bg-primaryBg space-y-12  lg:space-y-20">
      <div className="lg:px-20">
        <div className="flex justify-center items-center">
          {" "}
          <img
            src={Logo}
            alt=""
            className="h-[140px] w-[80px] md:h-[220px] md:w-[140px]  mx-auto mt-8"></img>
        </div>

        <div class="font-Poppins text-center text-xs md:text-lg justify-center mt-6 px-6 leading-5 italic">
          {" "}
          Empowering lives, one step at a time. The transformative journey and
          the lasting impact we're making in the lives of underprivileged
          children.
        </div>
      </div>

      <div class="flex flex-col space-y-8 lg:flex-row lg:w-full lg:px-20 lg:items-center lg:justify-center">
        <div className="text-center space-y-6 lg:w-1/2">
          <div class="font-Cheri text-4xl md:text-5xl font-bold">
            {" "}
            Spread{" "}
            <span className="text-secondaryBg font-Cheri text-3xl">💚</span>
          </div>
          <div class="mt-3 font-Poppins text-sm leading-5 px-4 md:text-lg italic">
            Join us in our impactful initiatives and make your contribution to
            transform lives and empower communities.
          </div>

          <button class="mt-10 bg-white hover:border-borderRed text-borderRed  font-Poppins font-semibold py-2 px-8 md:py-4 md:px-16 md:text-2xl border-black border-2 solid rounded-full">
            <HashLink to="/donate#donate">Donate</HashLink>
          </button>
        </div>

        <div className="box-border  bg-white p-4 rounded-2xl border-solid border-2 border-black shadow-lg mx-6 lg:w-1/2 lg:pl-10">
          <div className="text-center font-Cheri not-italic text-4xl font-bold md:text-5xl md:pt-4">
            Contact US
          </div>
          <div class="mt-5 font-Inter font-medium md:text-lg">
            <span class="font-bold">Address</span> : B 10/7 Sector 71, Noida,
            Uttar Pradesh
          </div>
          <div class="mt-3 font-inter font-medium md:text-lg">
            <span class="font-bold ">Mail: </span> contactus@bhavishyango.org
          </div>
          <div class="mt-3 font-Inter font-medium md:text-lg">
            <span class="font-bold">Phone Number</span> : +91-9654460578
          </div>

          <div className="box-border text-center justify-center items-center mt-5">
            <div class="font-Affection text-3xl font-semibold md:text-4xl">
              Follow Us
            </div>
            <div class="flex md:inline-flex md:space-x-4 px-auto">
              <div class="border-solid border-2 border-borderRed rounded-full p-3 ">
                <a href="https://www.facebook.com/profile.php?id=100069736024200">
                  <RiFacebookCircleLine class="fill-pink-60" />
                </a>
              </div>
              <div class="border-solid border-2 border-borderRed rounded-full p-3 ml-2">
                {" "}
                <a href="https://instagram.com/bhavishya.you?igshid=NDc0ODY0MjQ=">
                  <RiInstagramLine />
                </a>
              </div>
              <div class="border-solid border-2 border-borderRed rounded-full p-3 ml-2">
                {" "}
                <a href="https://www.linkedin.com/company/bhavishya-ngo/mycompany/">
                  <FiLinkedin />
                </a>
              </div>
              <div class="border-solid border-2 border-borderRed rounded-full p-3 ml-2">
                {" "}
                <RiWhatsappLine />
              </div>
              <div class="border-solid border-2 border-borderRed rounded-full p-3 ml-2">
                {" "}
                <a href="https://twitter.com/ngo_bhavishya ">
                  <RiTwitterLine />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="text-center grid grid-cols-1 space-y-2 items-center justify-center pb-4 md:grid-cols-2 lg:grid-cols-3 ">
        {Object.keys(footerLinks).map((item, i) => (
          <Link
            to={footerLinks[item]}
            key={item}
            class="font-poppins font-light text-sm md:text-xl hover:underline hover:underline-offset-4">
            {item}
          </Link>
        ))}
      </div>

      <div className="text-center bg-black text-white py-2 lg:text-xl text-sm px-2">
        Copyright Bhavishya NGO 2022 , All rights Reserved.
      </div>

      {/* <div class="bg-primaryBg">
        


        <div className=" grid grid-cols-2 gap-10 mx-auto mt-10 px-[20px]">


         
        </div>


      </div>
      <footer className="bg-gray-900 text-white text-center">
      </footer> */}
    </footer>
  );
};

export default Footer;
