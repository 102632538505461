import React from "react";
import { TimelineImg } from "../../assets";

const Timeline = () => {
  return (
    <div>
      <img
        src={TimelineImg}
        alt="Timeline Image"
        className="w-full h-full object-cover"
        data-aos="fade-up"
        data-aos-duration="3000"
      />
    </div>
  );
};

export default Timeline;
