import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";
import { About1Bg, About2Bg, About3Bg, DoubleColumnData } from "../assets";
import {
  Companies,
  DoubleColumn,
  Hero,
  KidsStory,
  ProgramSection,
  Timeline,
} from "../components";

const LandingPage = () => {
  const DoubleColumnImgs = [About1Bg, About2Bg, About3Bg];
  const position = ["left", "right", "left"];
  const DoubleColumnColors = ["bg-[#FDF1EA]", "bg-[#FAEFEF]", "bg-[#FDF2EE]"];

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="">
      <Hero />
      <div className="space-y-20 my-20">
        {DoubleColumnData.map((item, i) => (
          <div key={item.title} data-aos="fade-up" data-aos-duration="3000">
            <DoubleColumn
              title={item.title}
              description={item.description}
              img={DoubleColumnImgs[i]}
              position={position[i]}
              clrClass={DoubleColumnColors[i]}
            />
          </div>
        ))}
      </div>
      <Timeline />
      <ProgramSection />
      <KidsStory />
      <Companies />
    </div>
  );
};

export default LandingPage;
