import React from "react";
import { Autoplay, Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Hero1, Hero2, Hero3, HeroBg, OverlayHeroBg } from "../../assets";

const Hero = () => {
  let textSlideContent = [
    {
      id: 1,
      title: "For some children, nutrition is a distant dream.",
      description: [
        "India accounts for more than 3 out of 10 stunted children in the world.",
        "Bhavishya NGO is taking small steps towards a Nutritious India through the “Ahaar Sewa” initiative for these children.",
      ],
      img: Hero1,
    },
    {
      id: 2,
      title:
        "A community, society, and country prosper when its women and girls are empowered.",
      description: [
        "India still ranks 136 in 145 countries as per the Global Gender Gap Index Report 2022.",
        "Let us pledge to make an environment that promotes women to progress and excel.",
      ],
      img: Hero2,
    },
    {
      id: 3,
      title:
        "Children are the future of the nation and primary child education is the building block of an educated Community.",
      description: [
        "Join hands with Bhavishya NGO and help a child get a quality education through our “SPONSOR A KID” campaign.",
      ],
      img: Hero3,
    },
  ];

  return (
    <Swiper
      spaceBetween={30}
      centeredSlides={true}
      autoplay={{
        delay: 3500,
        disableOnInteraction: false,
      }}
      pagination={{
        clickable: false,
      }}
      navigation={false}
      modules={[Autoplay, Pagination, Navigation]}
      className="mySwiper">
      {textSlideContent.map((item, i) => (
        <SwiperSlide key={item.id}>
          <div className="relative text-center text-white">
            <div className="relative ">
              <img
                src={item.img}
                alt="Hero"
                className="object-cover h-96 w-full md:h-full md:w-full"
              />
              <div className="absolute top-0 left-0 bottom-0 right-0 bg-black bg-opacity-40 z-1"></div>
            </div>

            <div className="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 w-full py-auto z-2 space-y-2 md:space-y-5 lg:space-y-8">
              <div className="text-md  font-Poppins px-16 md:text-4xl lg:text-6xl font-semibold">
                {item.title}
              </div>

              {item.description.map((desc, i) => (
                <div
                  className="text-xs md:text-xl lg:text-2xl font-Poppins px-12"
                  key={desc}>
                  {desc}
                </div>
              ))}
            </div>

            {/* <div className="  text-xl z-2 font-Rubik md:text-5xl lg:text-7xl"></div>
      <div className="absolute -translate-x-1/2 -translate-y-1/2 bottom-2 left-1/2 text-lg z-2 font-Poppins md:text-2xl md:bottom-1/3 lg:text-4xl lg:bottom-80"></div> */}

            <div className="absolute top-0 left-0 hidden lg:visible">
              <img src={OverlayHeroBg} alt="Overlay" />
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Hero;
