import React from "react";

const DoubleColumn = ({ title, description, img, position, clrClass, key }) => {
  return (
    <div
      className={`flex flex-col items-start lg:items-center  lg:space-x-20 w-full ${clrClass} rounded-lg lg:pt-10 space-y-8 px-10 py-10 md:px-20 md:py-20  ${
        position === "left" ? "lg:flex-row lg:pr-16" : "lg:flex-row-reverse"
      }`}
      id="about-us">
      <div
        className={`lg:w-1/2 lg:py-20 ${
          position === "right" ? "lg:ml-12" : ""
        }`}>
        <img src={img} alt={title} className="" />
      </div>
      <div className="lg:w-1/2 space-y-6 lg:space-y-10">
        <div className="font-Cheri font-[800] text-4xl text-center md:text-6xl">
          {title}
        </div>
        <ul className="list-disc pl-6 font-Poppins text-md leading-6 lg:leading-8 space-y-4 md:text-2xl">
          {description.map((item, index) => (
            <li key={item[0] + index}>{item}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default DoubleColumn;
