import { Route, Routes } from "react-router-dom";
import { DonateSection, EdSupportTab, Footer, Navbar } from "./components";
import {
  Campaigns,
  Center,
  Contact,
  LandingPage,
  MediaPage,
  Programs,
} from "./pages";

function App() {
  return (
    <div className="">
      <Navbar />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/donate" element={<DonateSection />} />
        <Route path="/seekh" element={<EdSupportTab />} />
        <Route path="/media" element={<MediaPage />} />
        <Route path="/centers" element={<Center />} />
        <Route path="/programs" element={<Programs />} />
        <Route path="/campaigns" element={<Campaigns />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
