import React from "react";

const SingleProgramContainer = ({ title, description, img }) => {
  return (
    <div className="bg-secondaryBg inline-block px-4 py-4 md:p-8 object-contain w-full rounded-xl space-y-3 md:space-y-6 ">
      <div className="h-2/3">
        <img src={img} alt={title} className="object-cover" />
      </div>
      <div className="text-white h-1/3 space-y-2 md:space-y-6">
        <div className="font-Cheri text-2xl md:text-4xl">{title}</div>
        {title !== "Paryavaran Shiksha" ? (
          <div className="font-Poppins text-sm md:text-lg">{description}</div>
        ) : (
          <div className="font-Poppins  space-y-4">
            <div>
              <div className="font-bold text-md md:text-lg">
                Plantation Drive
              </div>
              <div className="text-sm md:text-md">
                In our impactful Plantation Drive initiative, we create a wave
                of environmental awareness. Through vibrant rallies, educational
                sessions, and meaningful tree plantations, we empower
                communities to become stewards of nature.
              </div>
            </div>
            <div>
              <div className="font-bold text-md md:text-lg">
                Solid Waste Management Initiative
              </div>
              <div className="text-sm md:text-md">
                Solid Waste Management The initiative transforms waste into
                wonder. Utilizing Ecobricks, crafted from waste plastic bottles
                stuffed with plastic wrappers, we've built durable park benches.
                This innovative approach not only recycles waste but also
                provides community spaces, demonstrating our commitment to
                environmental sustainability and creative problem-solving.
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SingleProgramContainer;
