import React from "react";
import { StarBorder } from "../../assets";

const DigitalMedia = () => {
  let mediaDetails = [
    {
      id: 1,
      title: "Bhavishya NGO inaugurates new branch in Bihar.",
      src: "https://www.youtube.com/embed/xo-bzej3AHo",
    },
    {
      id: 2,
      title:
        "We are glad to see our work appreciated by @zee_bihar_jharkhand@zeebiharjharkhand.",
      src: "https://www.youtube.com/embed/9WH5_Vn9QQQ",
    },
    {
      id: 3,
      title:
        "Dekhiye kaise Bhavishya NGO ne lockdown mein logo ki madad ki. Hindustaniyon Ki Awaaz",
      src: "https://www.youtube.com/embed/tU8cBUV0DEw",
    },
    {
      id: 4,
      title: "Bhavishya-video",
      src: "http://bhavishyango.org/video/1.mp4",
    },
  ];

  return (
    <div>
      <div class="bg-primaryBg space-y-8 mb-8">
        <div className="font-Rubik text-4xl md:text-6xl lg:text-8xl py-8  text-white text-center bg-secondaryBg">
          Digital Media
        </div>

        <div class="font-Poppins text-center mt-6 py-8  text-Poppins text-lg px-6 lg:text-3xl md:text-xl md:px-8 ">
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Velit
          accusamus, magnam facilis quibusdam quod fuga, quas qui distinctio
          dignissimos, expedita similique praesentium exercitationem quam
          consequuntur veniam quo impedit amet recusandae!
        </div>

        <div class="grid grid-cols-1 gap-10 px-6 lg:grid-cols-2">
          {mediaDetails.map((item, i) => (
            <iframe
              className="w-[380px] h-[250px] md:w-[550px] md:h-[300px] lg:w-[600px] lg:h-[450px] mx-auto "
              key={item.id}
              src={item.src}
              title={item.title}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen></iframe>
          ))}
        </div>

        <div className="mb-4 px-4">
          <img src={StarBorder} alt="Star Border" className="mx-auto" />
        </div>
      </div>
    </div>
  );
};

export default DigitalMedia;
