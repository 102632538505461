import React from "react";
import { ProgramsData } from "../assets";
import { SingleProgramContainer } from "../components";

const Campaigns = () => {
  return (
    <div className="bg-primaryBg p-4 pt-8 md:p-8 lg:p-12">
      <div className="bg-white border-black border-2 rounded-lg border-b-[20px] border-b-secondaryBg space-y-8 md:space-y-12 lg:space-y-16 py-20">
        <div className="font-Cheri text-5xl md:text-6xl text-center text-secondaryBg lg:text-8xl">
          Programs
        </div>

        <div className="flex flex-col items-center justify-center px-4 md:px-16 space-y-8 lg:grid lg:grid-cols-2 lg:space-y-0 lg:gap-16 lg:px-24">
          {ProgramsData.map((item, i) => (
            <div key={item.title}>
              <SingleProgramContainer
                title={item.title}
                description={item.description}
                img={item.img}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Campaigns;
