import React from "react";
import {
  PrintMedia1,
  PrintMedia2,
  PrintMedia3,
  PrintMedia4,
} from "../../assets";

const Printmedia = () => {
  let printMediaImages = [PrintMedia1, PrintMedia2, PrintMedia3, PrintMedia4];

  return (
    <div>
      <div className="bg-primaryBg">
        <div className="font-Rubik text-4xl md:text-6xl lg:text-8xl py-8  text-white text-center bg-secondaryBg">
          Print Media
        </div>

        <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:items-center mx-auto ">
          {printMediaImages.map((i, item) => (
            <div
              className="flex border-solid border-2 border-borderRed p-4 m-4 md:h-[400px]"
              key={item}>
              <img
                src={i}
                className="mx-auto hover:scale-125 duration-1000"
                alt="Print Media - Newspaper"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Printmedia;
