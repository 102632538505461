import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { EdSupportBg, EventsBg, SponsorshipBg } from "../../assets";

const ProgramSection = () => {
  return (
    <div
      className="flex flex-col lg:flex-row items-center justify-center lg:py-20 lg:space-x-6 bg-primaryBg px-6 py-6 space-y-4 md:space-y-10 lg:space-y-0"
      data-aos="fade-up"
      data-aos-duration="3000">
      <div className="relative">
        <img src={EdSupportBg} alt="Ed Support Image" />
        <div className="absolute bottom-10 lg:mx-10 lg:space-y-10">
          <div className="lg:space-y-6 space-y-4 px-4">
            <div className="font-Poppins text-white text-4xl md:text-6xl font-bold">
              Seekh
            </div>
            <div className="font-Poppins text-white md:text-3xl md:leading-10">
              Join the Movement, Ignite Change! Get in touch today and become a
              part of our mission to create a better world. Together, we can
              make a difference!
            </div>
          </div>
          <div className="flex justify-end mt-4 px-6 ">
            <Link to="/seekh">
              <button className="font-Poppins text-white text-lg px-4 py-2  bg-secondaryBg  rounded-lg md:text-3xl md:my-6">
                help us help them
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className="relative">
        <img
          src={EventsBg}
          alt="Events Image"
          className="h-[240px] w-[500px] md:h-[500px] md:w-[700px] lg:h-full lg:w-full"
        />
        <Link to="/programs">
          <div className="absolute font-Poppins lg:-rotate-90 text-white text-4xl font-extrabold bottom-[42%] text-center left-[17%] md:left-[25%] md:text-6xl lg:text-9xl lg:-left-[1%]">
            Programs
          </div>
        </Link>
      </div>
      <div className="relative">
        <img
          src={SponsorshipBg}
          alt="Sponsorship Image"
          className="h-[240px] w-[500px] md:h-[500px] md:w-[700px] lg:h-full lg:w-full"
        />
        <Link to="/donate">
          <div className="absolute font-Poppins lg:-rotate-90 text-white text-4xl font-extrabold bottom-[42%] text-center left-[17%] md:left-[25%] md:text-6xl lg:text-8xl lg:-left-[1%]">
            Sponsorship
          </div>
        </Link>
      </div>
    </div>
  );
};

export default ProgramSection;
