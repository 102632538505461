// Landing Page
export const About1Bg =
  "https://ik.imagekit.io/bhavishya/web-final/LandingPage/about1.png?updatedAt=1689694445944";
export const About2Bg =
  "https://ik.imagekit.io/bhavishya/web-final/LandingPage/about2.png";
export const About3Bg =
  "https://ik.imagekit.io/bhavishya/web-final/LandingPage/about3.png";
export const EdSupportBg =
  "https://ik.imagekit.io/bhavishya/web-final/LandingPage/edsupport.png";
export const EventsBg =
  "https://ik.imagekit.io/bhavishya/web-final/LandingPage/events.png?updatedAt=1680110935732";
export const Hero1 =
  "https://ik.imagekit.io/bhavishya/web-final/LandingPage/hero1.png?updatedAt=1680110939744";
export const Hero2 =
  "https://ik.imagekit.io/bhavishya/web-final/LandingPage/hero2.png?updatedAt=1680110938984";
export const Hero3 =
  "https://ik.imagekit.io/bhavishya/web-final/LandingPage/hero3.png?updatedAt=1680110938321";
export const LogoPhone =
  "https://ik.imagekit.io/bhavishya/web-final/LandingPage/LogoPhone.png?updatedAt=1680110933262";
export const OverlayHeroBg =
  "https://ik.imagekit.io/bhavishya/web-final/LandingPage/overlayHeroBg.png?updatedAt=1680110933266";
export const SponsorshipBg =
  "https://ik.imagekit.io/bhavishya/web-final/LandingPage/sponsorship.png?updatedAt=1680110935619";
export const TimelineImg =
  "https://ik.imagekit.io/bhavishya/web-final/LandingPage/timeline'.png?updatedAt=1689880535617";
export const CenterNoida71 =
  "https://ik.imagekit.io/bhavishya/web-final/Center/NoidaSector71-Center.jpeg?updatedAt=1689407241196";
export const Centre1 =
  "https://ik.imagekit.io/bhavishya/web-final/Misc/Centers/Center1.png?updatedAt=1680110950176";
export const Centre2 =
  "https://ik.imagekit.io/bhavishya/web-final/Misc/Centers/Center2.png?updatedAt=1680110950219";
export const Centre3 =
  "https://ik.imagekit.io/bhavishya/web-final/Misc/Centers/Center3.png?updatedAt=1680110950120";
export const CloudImg =
  "https://ik.imagekit.io/bhavishya/web-final/Misc/cloudImg.png?updatedAt=1680110949548";
export const StarBorder =
  "https://ik.imagekit.io/bhavishya/web-final/Misc/DigitalMedia/StarBorder.png?updatedAt=1680110953519";
export const QRCode =
  "https://ik.imagekit.io/bhavishya/web-final/Misc/qrcode.jpeg?updatedAt=1691503327203";
export const EdSupportPageImg =
  "https://ik.imagekit.io/bhavishya/web-final/Misc/Seekh.png?updatedAt=1690117438871";
export const Logo =
  "https://ik.imagekit.io/bhavishya/web-final/logo%20Updates%20%20(1).png?updatedAt=1701111923138";
export const PrintMedia1 =
  "https://ik.imagekit.io/bhavishya/web-final/Misc/PrintMedia/PrintMedia1.png?updatedAt=1680110952740";
export const PrintMedia2 =
  "https://ik.imagekit.io/bhavishya/web-final/Misc/PrintMedia/PrintMedia2.png?updatedAt=1680110950343";
export const PrintMedia3 =
  "https://ik.imagekit.io/bhavishya/web-final/Misc/PrintMedia/PrintMedia4.png?updatedAt=1680110950203";
export const PrintMedia4 =
  "https://ik.imagekit.io/bhavishya/web-final/Misc/PrintMedia/PrintMedia3.png?updatedAt=1680110949861";
export const TimeLineSvg =
  "https://ik.imagekit.io/bhavishya/web-final/LandingPage/timeline.svg?updatedAt=1681583642913";
export const Kid1Img =
  "https://ik.imagekit.io/bhavishya/web-final/LandingPage/Nirmal-Kid1.jpeg?updatedAt=1689880535416";
export const Kid2Img =
  "https://ik.imagekit.io/bhavishya/web-final/LandingPage/Kids2.jpeg?updatedAt=1689705922750";

// Companies
export const Company1Img =
  "https://ik.imagekit.io/bhavishya/web-final/companies/netzsch.png?updatedAt=1682183919169";
export const Company2Img =
  "https://ik.imagekit.io/bhavishya/web-final/companies/taaza.png?updatedAt=1682183919123";
export const Company3Img =
  "https://ik.imagekit.io/bhavishya/web-final/companies/pinelabs.png?updatedAt=1682183918907";
export const Company4Img =
  "https://ik.imagekit.io/bhavishya/web-final/companies/opstree.png?updatedAt=1682183918730";
export const Company5Img =
  "https://ik.imagekit.io/bhavishya/web-final/companies/mobiforce.png?updatedAt=1682183918563";
export const Company6Img =
  "https://ik.imagekit.io/bhavishya/web-final/companies/AFCLogo.jpeg?updatedAt=1688400107267";

// contact us svgs
export const partnerWithUsSvg =
  "https://ik.imagekit.io/bhavishya/web-final/Misc/deal.svg?updatedAt=1682270210471";
export const sponsorAChildSvg =
  "https://ik.imagekit.io/bhavishya/web-final/Misc/empathy.svg?updatedAt=1682270210462";
export const volunteerWithUsSvg =
  "https://ik.imagekit.io/bhavishya/web-final/Misc/love.svg?updatedAt=1682270210441";

// test
export const testImageProject =
  "https://ik.imagekit.io/bhavishya/web-final/test/testimage.png?updatedAt=1682248669617";
export const testMapImage =
  "https://ik.imagekit.io/bhavishya/web-final/test/map.png?updatedAt=1682255753351";
export const testKidRameshImg =
  "https://ik.imagekit.io/bhavishya/web-final/test/testKidRamesh.png?updatedAt=1682324271100";

// Kid component
export const cloudSvg =
  "https://ik.imagekit.io/bhavishya/web-final/Misc/Cloud.svg?updatedAt=1682323975304";

export const DoubleColumnData = [
  {
    title: "About Us",
    description: [
      "Bhavishya is a non - governmental organization that became officially registered in accordance with the indian trust act, 1882 in the year 2016.",
      "The organization was founded in 2016 and since then has been working to improve the lives of marginalized communities, with a particular emphasis on the youth of India, by providing them with access to high quality education at our educational facilities, which are spread across the country.",
      "In addition to providing educational opportunities, the organization also runs Skill Development and Vocational Training programs and Awareness campaigns to promote legal rights, equality, empowerment, health, hygiene, and prevention of exploitation, discrimination, and abuse.",
    ],
    img: "../../assets/LandingPage/about1.png",
    position: "left",
  },
  {
    title: "Mission",
    description: [
      "Providing quality education to underprivileged children, with access to quality teachers, educational resources, and an environment that encourages learning and exploration.",
      "Building strong partnerships with local organizations, governments, and businesses to provide the resources needed to ensure the development of the community. ",
      "Creating access to healthcare and providing medical care and support to children and their families. ",
      "Developing a holistic approach to poverty reduction and creating opportunities for sustainable livelihoods.",
      "Establishing a safe and secure environment for children and working to prevent abuse, exploitation, and discrimination.",
      // "Developing innovative approaches to advocacy and campaigns to raise awareness of the needs of underprivileged children.",
      // "Establishing programs to help children develop skills and knowledge for their future.",
    ],
    position: "right",
  },
  {
    title: "Vision",
    description: [
      "To holistically nurture and develop the youth, making them the pillars of society capable of leading INDIA.",
    ],
    position: "left",
  },
];

export const ProgramsData = [
  {
    title: "Seekh Initiative",
    description: [
      "Join the Movement, Ignite Change! Get in touch today and become a part of our mission to create a better world. Together, we can make a difference!",
    ],
    img: "https://ik.imagekit.io/bhavishya/web-final/Programs/Seekh.jpeg?updatedAt=1689871797370",
  },
  {
    title: "Shiksha Rickshaw",
    description: [
      "It is aimed at bringing education to underprivileged children living in poverty-stricken areas. An E-rickshaw equipped with a teacher and other educational resources reaches to the slum area.",
    ],
    img: "https://ik.imagekit.io/bhavishya/web-final/Programs/shiksharickshaw.jpeg?updatedAt=1689703139068",
  },
  {
    title: "Vocational Training Center",
    description: [
      "The center offers stitching classes and teaches business skills. Women are equipped with skills to start their businesses or secure employment in the textile industry.",
    ],
    img: "https://ik.imagekit.io/bhavishya/web-final/Programs/Vocational_Training_Center.png?updatedAt=1689693988345",
  },
  {
    title: "Old Age Home",
    description: [
      "Our latest project, an old age home for the poor and helpless. This initiative is currently under construction and will be located in the serene and picturesque city of Nainital. why we are committed to creating a welcoming and supportive environment for elderly individuals who have been left behind by society.",
    ],
    img: "https://ik.imagekit.io/bhavishya/web-final/Programs/oldage.jpeg?updatedAt=1689703066203",
  },
  {
    title: "Computer Education Program",
    description: [
      "Bhavishya NGO's computer center is a dedicated initiative aimed at providing essential computer literacy to underprivileged children in the Noida area. The center offers a comprehensive curriculum, introducing students to the fundamentals of computers and their components.",
    ],
    img: "https://ik.imagekit.io/bhavishya/web-final/Programs/computer-education-program.jpeg?updatedAt=1689879878770",
  },
  {
    title: "Sponsor A Kid Program",
    description: [
      "With our Sponsor a Kid Program, you can make a real difference in the life of a child by sponsoring their education for a minimal amount. Your donation will be used to provide the child with access to quality education in the nearby government school, books, stationery, uniforms, and other necessary resources.",
    ],
    img: "https://ik.imagekit.io/bhavishya/web-final/Programs/Sponser_a_kid.png?updatedAt=1689693991366",
  },
];

export const CampaignsData = [
  {
    title: "Har Baste Mein Kitaab",
    description: [
      "We provide bags of study materials to underprivileged children studying with us, ensuring that they do not lack the necessary tools they need to succeed.",
    ],
    img: "https://ik.imagekit.io/bhavishya/web-final/Campaigns/har-baste-me-kitab.jpeg?updatedAt=1689871711825",
  },
  {
    title: "Safe Women One Pad",
    description: [
      "Our Safe Women One Pad initiative is a crucial step towards ensuring women's health and safety during their menstrual cycles. We distribute sanitary pads to women in the slums where it is not accessible.",
    ],
    img: "https://ik.imagekit.io/bhavishya/web-final/Campaigns/Onewomanpad.png?updatedAt=1689693909012",
  },
  {
    title: "Ahaar Sewa Initiative",
    description: [
      "Our initiative is dedicated to ensuring no one goes hungry, with Ahaar Sewa we strive to provide nutritious meals to the students once a day, every day spreading smiles and nourishing lives. Together, let's combat hunger and make a positive impact on our community.",
    ],
    img: "https://ik.imagekit.io/bhavishya/web-final/Campaigns/Ahaar_Sewa.png?updatedAt=1689693905936",
  },
  {
    title: "Paryavaran Shiksha",
    description: [
      "plantation drive In our impactful Plantation Drive initiative, we create a wave of environmental awareness.Through vibrant rallies, educational sessions, and meaningful tree plantations, we empower communities to become stewards of nature.Together, we inspire change, protect our precious ecosystem, and foster a greener, sustainable future for all.",
    ],
    img: "https://ik.imagekit.io/bhavishya/WhatsApp%20Image%202023-11-29%20at%2023.16.45.jpeg?updatedAt=1701280116190",
  },
  {
    title: "Winter Donation Drive",
    description: [
      "In India, many homeless families struggle to stay warm during the winter months. Winter Donation Drive provides warm clothing and blankets to those on the streets.",
    ],
    img: "https://ik.imagekit.io/bhavishya/web-final/Campaigns/winter-donation-drive.jpeg?updatedAt=1689871711816",
  },
  {
    title: "Ujjwal Bhavishya Workshop",
    description: [
      "Our Ujjwal Bhavishya Workshop is designed to provide vocational training and other engaging programs to help children develop the skills they need to succeed in life. Different resource persons provide training in various skills.",
    ],
    img: "https://ik.imagekit.io/bhavishya/web-final/Campaigns/UjjwalBhavishya.png?updatedAt=1689693906732",
  },
];
