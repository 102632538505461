import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { Logo, LogoPhone } from "../../assets";

const LargeScreenNavbar = () => {
  let navLinks = {
    "About US": "/#about-us",
    Program: "/programs",
    Campaign: "/campaigns",
    Centers: "/centers",
    "Get in touch": "/contact",
  };

  return (
    <div className="bg-secondaryBg">
      <div className="p-4 flex items-center justify-between md:p-2 md:px-4">
        <div className="">
          <Link to="/">
            <img src={Logo} alt="Bhavishya Logo" className="w-12 md:w-24" />
          </Link>
        </div>
        <div className="flex text-center space-x-12">
          {Object.keys(navLinks).map((item, i) => (
            <HashLink
              className="font-Poppins font-[800] text-white text-2xl  "
              key={item}
              to={navLinks[item]}>
              {item}
            </HashLink>
          ))}
        </div>
        <div className="text-center">
          <Link
            to="/donate"
            className="bg-black px-6 py-4 text-white font-[800] rounded-md text-2xl hover:border-black hover:bg-white hover:text-black">
            Donate us
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LargeScreenNavbar;
