import emailjs from "@emailjs/browser";
import React, { useState } from "react";
import { Toaster, toast } from "react-hot-toast";
import { FiLinkedin } from "react-icons/fi";
import {
  RiFacebookCircleLine,
  RiInstagramLine,
  RiTwitterLine,
  RiWhatsappLine,
} from "react-icons/ri";
import { Link } from "react-router-dom";
import { Logo, QRCode } from "../../assets";

const DonateSection = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [location, setLocation] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [inquiry, setInquiry] = useState("");
  const [status, setStatus] = useState("Individual");

  const handleOnSubmit = (e) => {
    e.preventDefault();
    console.log(name, phone);
    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        {
          senders_name: name,
          senders_phone: phone,
          senders_location: location,
          senders_email: email,
          senders_address: address,
          senders_inquiry: inquiry,
          senders_status: status,
        },
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
      )
      .then(
        (result) => {
          setName("");
          setPhone("");
          setLocation("");
          setEmail("");
          setAddress("");
          setInquiry("");
          setStatus("Individual");

          toast.success("Form submitted successfully!");
        },
        (error) => {
          toast.error("Something went wrong!! Please try again");
        },
      );
  };

  return (
    <div className="flex flex-col lg:flex-row lg:items-center text-center lg:justify-center lg:w-full bg-secondaryBg">
      <Toaster position="top-center" reverseOrder={false} />
      <div className="flex flex-col items-center justify-center bg-primaryBg lg:w-1/2 lg:px-16 py-2 space-y-8 px-4">
        <div>
          <imgREACT_APP_PUBLIC_KEY
            src={Logo}
            alt="Bhavishya Logo"
            className="w-1/5 h-1/5 mx-auto"
          />
        </div>

        <div className="space-y-4 flex flex-col itemIndividuals-center justify-center">
          <div className="font-Cheri text-center text-2xl">
            For UPI transfer (Scan the QR code)
          </div>
          <div className="font-Inter text-center text-3xl">
            bhavishyango@icici
          </div>
          <div className="flex items-center justify-center">
            <img src={QRCode} alt="QR Code for Donation" />
          </div>
        </div>
        <div className="space-y-4">
          <div className="font-Cheri text-center text-2xl">
            for bank Transfer
          </div>
          <div className="bg-white px-20 py-12 rounded-xl space-y-2 font-Poppins font-semibold border-black border-[1px] ">
            <div>Name:- Bhavishya Trust</div>
            <div>Bank Name:- ICICI Bank</div>
            <div>Account Number:- 113905000444</div>
            <div>IFSC Code:- ICIC0001139</div>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center space-y-4">
          <div className="font-Affection text-4xl">Follow Us</div>
          <div class="flex md:inline-flex space-x-2">
            <div class="border-solid border-2 border-[#EB5A57] rounded-lg p-3 bg-[#F8F8F8]">
              <a href="https://www.facebook.com/profile.php?id=100069736024200">
                <RiFacebookCircleLine class="fill-[#EB5A57]" />
              </a>
            </div>
            <div class="border-solid border-2 border-[#EB5A57] rounded-lg p-3 bg-[#F8F8F8]">
              {" "}
              <a href="https://instagram.com/bhavishya.you?igshid=NDc0ODY0MjQ=">
                <RiInstagramLine class="fill-[#EB5A57]" />
              </a>
            </div>
            <div class="border-solid border-2 border-[#EB5A57] rounded-lg p-3 bg-[#F8F8F8]">
              {" "}
              <a href=" https://www.linkedin.com/company/bhavishya-ngo/mycompany/">
                <FiLinkedin class="fill-[#EB5A57]" />
              </a>
            </div>
            <div class="border-solid border-2 border-[#EB5A57] rounded-lg p-3 bg-[#F8F8F8]">
              {" "}
              <RiWhatsappLine class="fill-[#EB5A57]" />
            </div>
            <div class="border-solid border-2 border-[#EB5A57] rounded-lg p-3 bg-[#F8F8F8]">
              {" "}
              <a href="https://twitter.com/ngo_bhavishya">
                <RiTwitterLine class="fill-[#EB5A57]" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center lg:w-1/2 lg:px-16 py-2 space-y-8 px-4">
        <div className="font-Cheri text-white font-outline-2 drop-shadow-md text-6xl font-bold">
          Donate.
        </div>
        <div className="font-Poppins text-lg text-white">
          1 out of every 2 street connect children have suffered verbal,
          physical or sexual abuse. The time is now to make a difference, help
          us help them!
        </div>
        <form className="space-y-10" onSubmit={handleOnSubmit}>
          <input
            type="text"
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
            name="senders_name"
            class="mt-1 w-full border-b-2 border-0 bg-secondaryBg text-black font-Poppins border-black placeholder-black focus:outline-none focus:border-primaryBg focus:ring-primaryBg focus:ring-1"
            placeholder="Your Name"
          />
          <div className="flex justify-center items-center space-x-24">
            <input
              value={phone}
              required
              onChange={(e) => setPhone(e.target.value)}
              name="senders_phone"
              type="tel"
              class="mt-1 w-full border-b-2 border-0 bg-secondaryBg text-black font-Poppins border-black placeholder-black focus:outline-none focus:border-primaryBg focus:ring-primaryBg focus:ring-1"
              placeholder="Phone Number"
            />
            <input
              value={location}
              required
              onChange={(e) => setLocation(e.target.value)}
              name="senders_location"
              type="text"
              class="mt-1 w-full border-b-2 border-0 bg-secondaryBg text-black font-Poppins border-black placeholder-black focus:outline-none focus:border-primaryBg focus:ring-primaryBg focus:ring-1"
              placeholder="Location"
            />
          </div>
          <input
            value={email}
            required
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            name="senders_email"
            class="mt-1 w-full border-b-2 border-0 bg-secondaryBg text-black font-Poppins border-black placeholder-black focus:outline-none focus:border-primaryBg focus:ring-primaryBg focus:ring-1"
            placeholder="Email"
          />
          <textarea
            value={address}
            required
            onChange={(e) => setAddress(e.target.value)}
            name="senders_address"
            rows="3"
            class="mt-1 w-full border-b-2 border-0 bg-secondaryBg text-black font-Poppins border-black placeholder-black focus:outline-none focus:border-primaryBg focus:ring-primaryBg focus:ring-1"
            placeholder="Address"
          />
          <input
            value={inquiry}
            required
            onChange={(e) => setInquiry(e.target.value)}
            type="text"
            name="senders_inquiry"
            class="mt-1 w-full border-b-2 border-0 bg-secondaryBg text-black font-Poppins border-black placeholder-black focus:outline-none focus:border-primaryBg focus:ring-primaryBg focus:ring-1"
            placeholder="Inquiry"
          />
          <label className="inline-flex items-center justify-start space-x-4">
            <span>Donated By: </span>
            <select
              className="form-select bg-white border-black border-2 rounded-xl px-8 font-Poppins text-lg"
              name="senders_status"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              id="">
              <option value="Individual">Individual</option>
              <option value="Organization">Organization</option>
            </select>
          </label>
          <div className="flex justify-end">
            <button
              className="font-Poppins py-2 px-6 bg-white text-2xl rounded-xl border-2 font-large"
              type="submit">
              Send
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default DonateSection;
