import emailjs from "@emailjs/browser";
import React, { useState } from "react";
import { Toaster, toast } from "react-hot-toast";
import {
  Logo,
  partnerWithUsSvg,
  sponsorAChildSvg,
  testMapImage,
  volunteerWithUsSvg,
} from "../assets";

const Contact = () => {
  const [goal, setGoal] = useState("None");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [location, setLocation] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const handleOnSubmit = (e) => {
    e.preventDefault();
    console.log(name, phone);
    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_CONNECT_TEMPLATE_ID,
        {
          senders_name: name,
          senders_phone: phone,
          senders_location: location,
          senders_email: email,
          senders_message: message,
          senders_goal: goal,
        },
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
      )
      .then(
        (result) => {
          setName("");
          setPhone("");
          setLocation("");
          setEmail("");
          setMessage("");
          setGoal("");

          toast.success("Form submitted successfully!");
        },
        (error) => {
          toast.error("Something went wrong!! Please try again");
        },
      );
  };

  return (
    <div className="bg-primaryBg space-y-8">
      <Toaster position="top-center" reverseOrder={false} />
      <div className="flex flex-col lg:flex-row lg:items-center text-center lg:justify-center lg:w-full  space-y-8 lg:space-y-0">
        <div className="flex flex-col items-center justify-center  lg:w-1/2 lg:px-16 py-2 space-y-8 px-4">
          <div>
            <img
              src={Logo}
              alt="Bhavishya Logo"
              className="w-1/5 h-1/5 mx-auto"
            />
          </div>
          <div className="font-Poppins font-thin leading-7 md:text-xl">
            Join the Movement, Ignite Change! Get in touch today and become a
            part of our mission to create a better world. Together, we can make
            a difference!
          </div>
          <img
            src={testMapImage}
            alt="QR Code for Donation"
            className="md:scale-[0.8]"
          />
        </div>
        <div className="flex flex-col items-center justify-center lg:w-1/2 lg:px-8 py-2 space-y-8 px-4 bg-secondaryBg rounded-xl lg:mx-12 md:mx-8 mx-4 pb-12">
          <div className="font-Cheri text-white font-outline-2 drop-shadow-md text-3xl md:text-5xl  font-bold pt-12">
            Connect . Help . Do Good
          </div>
          <div className="font-Poppins text-sm md:text-lg text-white">
            1 out of every 2 street connect children have suffered verbal,
            physical or sexual abuse. The time is now to make a difference, help
            us help them!
          </div>
          <form
            className="space-y-10 px-4 lg:px-0 font-Cheri"
            onSubmit={handleOnSubmit}>
            <div className="flex flex-col md:flex-row items-start justify-start md:space-x-6 lg:space-x-10 md:items-center md:justify-center">
              <div className="text-lg">What you are looking for?</div>
              <div className="space-x-2">
                <input
                  type="checkbox"
                  className="scale-150 rounded-md"
                  name="senders_goal"
                  value={goal}
                  onChange={(e) => setGoal("Volunteer")}
                  checked={goal === "Volunteer"}
                />
                <label className="text-lg" htmlFor="">
                  Volunteer
                </label>
              </div>
              <div className="space-x-2">
                <input
                  type="checkbox"
                  className="scale-150 rounded-md"
                  placeholder="Your Name"
                  name="senders_goal"
                  value={goal}
                  onChange={(e) => setGoal("Pledge")}
                  checked={goal === "Pledge"}
                />
                <label className="text-lg" htmlFor="">
                  Pledge
                </label>
              </div>
              <div className="space-x-2">
                <input
                  type="checkbox"
                  className="scale-150 rounded-md"
                  placeholder="Your Name"
                  name="senders_goal"
                  value={goal}
                  onChange={(e) => setGoal("Partner")}
                  checked={goal === "Partner"}
                />
                <label className="text-lg" htmlFor="">
                  Partner with Us
                </label>
              </div>
            </div>
            <input
              type="text"
              class="mt-1 w-full border-b-2 border-0  text-black font-Poppins border-black placeholder-black focus:outline-none focus:border-primaryBg focus:ring-primaryBg focus:ring-1 bg-secondaryBg"
              placeholder="Your Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              name="senders_name"
              required
            />
            <div className="flex flex-col lg:flex-row justify-center items-center lg:space-x-24 space-y-8 lg:space-y-0">
              <input
                type="tel"
                class="mt-1 w-full border-b-2 border-0  text-black font-Poppins border-black placeholder-black focus:outline-none focus:border-primaryBg focus:ring-primaryBg focus:ring-1 bg-secondaryBg"
                placeholder="Phone Number"
                value={phone}
                required
                onChange={(e) => setPhone(e.target.value)}
                name="senders_phone"
              />
              <input
                type="text"
                class="mt-1 w-full border-b-2 border-0  text-black font-Poppins border-black placeholder-black focus:outline-none focus:border-primaryBg focus:ring-primaryBg focus:ring-1 bg-secondaryBg"
                placeholder="Location"
                value={location}
                required
                onChange={(e) => setLocation(e.target.value)}
                name="senders_location"
              />
            </div>
            <input
              type="email"
              class="mt-1 w-full border-b-2 border-0  text-black font-Poppins border-black placeholder-black focus:outline-none focus:border-primaryBg focus:ring-primaryBg focus:ring-1 bg-secondaryBg"
              placeholder="Email"
              value={email}
              required
              onChange={(e) => setEmail(e.target.value)}
              name="senders_email"
            />

            <input
              type="text"
              class="mt-1 w-full border-b-2 border-0  text-black font-Poppins border-black placeholder-black focus:outline-none focus:border-primaryBg focus:ring-primaryBg focus:ring-1 bg-secondaryBg"
              placeholder="Message"
              required
              onChange={(e) => setMessage(e.target.value)}
              name="senders_message"
              value={message}
            />
            <div className="flex justify-end">
              <button
                className="font-Poppins py-2 px-6 bg-white text-lg lg:text-2xl rounded-xl border-2 font-large"
                type="submit">
                Send
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row items-center justify-evenly space-y-4 lg:space-y-0 px-10">
        <div className="flex items-center justify-center bg-secondaryBg px-6 py-1 rounded-2xl">
          <img
            src={sponsorAChildSvg}
            alt="Sponsor A child"
            className="scale-50 lg:scale-[0.7]"
          />
          <div className="font-Cheri text-white text-xl lg:text-3xl">
            Sponsor a Child
          </div>
        </div>
        <div className="flex items-center justify-center bg-secondaryBg px-6 py-1 rounded-2xl">
          <img
            src={volunteerWithUsSvg}
            alt="Volunteer with us"
            className="scale-50 lg:scale-[0.7]"
          />
          <div className="font-Cheri text-white text-xl lg:text-3xl">
            Volunteer with us
          </div>
        </div>
        <div className="flex items-center justify-center bg-secondaryBg px-6 py-1 rounded-2xl">
          <img
            src={partnerWithUsSvg}
            alt="Partner with us"
            className="scale-50 lg:scale-[0.7]"
          />
          <div className="font-Cheri text-white text-xl lg:text-3xl">
            Partner with us
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
